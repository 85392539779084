// DATATABLES:

.dataTables_filter {
    display: none;
}

.dataTables_scrollHead {
    overflow: visible!important;
    transform: translateY(3px);
    thead th {
        border-bottom: 1px dashed $yellow!important;
    }
}

.dataTables_scrollBody {
    border-bottom: none !important;
    overflow: visible !important;
}

.dataTable {
    width: 100% !important;
    border: none !important;
    border-spacing: 1px!important;
    &.accordion__table_img {
        border-bottom: 1px dashed #fcaf17 !important;
    }
    thead {
        th {
            color: $dark-gray;
            font-family: $Roboto-BoldCondensed;
            font-size: 10px;
            line-height: 18px;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            padding-left: 5px !important;
            text-align: left;
            &:last-child {
                padding-right: 0 !important;
            }
            &.style_center-text {
                text-align: center !important;
                padding-left: 18px !important;
            }
        }
        .dataTables_scrollHead & {
            .sorting, .sorting_asc, .sorting_desc {
                background-image: none;
                position: relative;
                pointer-events: none;
                &.sorting-active {
                    cursor: pointer;
                    pointer-events: auto;
                    &::after {
                        content: "";
                        position: absolute;
                        background-image: url("../img/content/arrow_period_up_icon.svg");
                        background-repeat: no-repeat;
                        width: 6px;
                        height: 14px;
                        z-index: 1;
                        margin: -2px 0 0 2px;
                    }
                }
            }
            .sorting_asc.sorting-active {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }
    }
    tbody {
        tr {
            min-height: 59px;
            border-bottom: none!important;
            transition: background-color .6s ease;
            td {
                text-align: left !important;
                padding: 0 4px !important;
                border-bottom: 1px dashed $middle-gray;
                a {
                    color: $dark;
                    display: block;
                    padding: 17.5px 0;
                }
                &.style_bold {
                    text-align: left;
                    font-family: $Roboto-BoldCondensed;
                    line-height: 18px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }
                &.style_center-text {
                    text-align: center !important;
                }
                .style_center-text {
                    text-align: center;
                }
            }
            &.has-link {
                cursor: pointer;
                &_unchecked {
                    background-color: #fafafa;
                }
            }
        }
    }
    @include res(tab) {
        width: 1090px !important;
    }
}

.table {
    &__icon {
        display: flex!important;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
        svg {
            width: 22px;
        }
        &_status {
            .accordion__table & {
                padding-left: 30px!important;
            }
        }
        &_link {
            transition: all .4s ease;
        }
    }
    &__icon-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 64px;
    }
    &__notification {
        width: 100%!important;
        tbody tr td {
            padding: 7px 5px!important;
            height: 40px;
        }
    }
    &__svg {
        width: 30px;
        height: 30px;
        display: flex!important;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
        &_single {
            margin-left: 18px!important;
        }
        &_link {
            transition: background-color .4s ease;
        }
    }
    &__text {
        flex-grow: 1;
        text-align: center;
        justify-content: space-between;
        .col__status & {
            text-align: left;
        }
    }
}

.col {
    &__download {
        width: 160px;
        padding: 2.5px 0 !important;
        &:hover {
            background-color: $white!important;
        }
        .btn-transparent {
            width: 158px;
            padding: 6.5px 0;
            span {
                width: 12px;
                height: 12px;
            }
        }
    }
    &__img {
        width: 195px;
        padding: 0!important;
    }
    &__report:last-child {
        padding: 0 18px!important;
    }
    &__status {
        width: 145px;
        min-width: 145px;
    }
    &__company {
        width: 220px;
        min-width: 180px;
        max-width: 200px;
    }
    &__w60 {
        width: 60px;
        max-width: 60px;
    }
    &__w65 {
        width: 65px;
        min-width: 65px;
        max-width: 65px;
    }
    &__w70 {
        width: 70px;
    }
    &__w80 {
        width: 90px;
    }
    &__w120 {
        width: 120px;
        @include res(tab) {
            width: 90px;
        }
    }
    &__period {
        width: 130px;
        min-width: 130px;
    }
    &__w150 {
        width: 150px;
        @include res(tab) {
            width: 140px;
        }
    }
    &__w180 {
        width: 180px;
    }
    &__w200 {
        width: 200px;
        @include res(tab) {
            width: 140px;
        }
    }
    &__w300 {
        width: 300px;
        min-width: 200px;
        max-width: 100%;
    }
    &__accordion-img-adaptive {
        @include res(tab) {
            width: 220px;
        }
    }
}

.style {
    &_bold {
        font-family: $Roboto-BoldCondensed;
        text-transform: uppercase;
    }
    &_small {
        font-size: 10px;
        font-family: $Roboto-BoldCondensed;
        text-transform: uppercase;
    }
    &_dark-color {
        font-family: $Roboto-BoldCondensed;
        line-height: 18px;
        text-transform: uppercase;
    }
    &_gray-color {
        color: $gray !important;
        a {
            color: $gray !important;
        }
    }
    &_red-color {
        color: $red !important;
        font-family: $Roboto-BoldCondensed;
        line-height: 18px;
        text-transform: uppercase;
    }
    &_orange-color {
        color: $yellow !important;
        a {
            color: $yellow !important;
        }
    }
    &_green-color {
        color: $green !important;
        a {
            color: $green !important;
        }
    }
}

// TABLE MASK FOR TABLET:

@mixin bg-gradient-left-to-right ($start-colour, $end-colour) {
    background-color: $start-colour;
    background-image: -webkit-gradient(linear, left top, left bottom, from($start-colour), to($end-colour));
    background-image: -webkit-linear-gradient(left, $start-colour, $end-colour);
    background-image:    -moz-linear-gradient(left, $start-colour, $end-colour);
    background-image:     -ms-linear-gradient(left, $start-colour, $end-colour);
    background-image:      -o-linear-gradient(left, $start-colour, $end-colour);
    background-image:         linear-gradient(left, $start-colour, $end-colour);
    filter:            progid:DXImageTransform.Microsoft.gradient(start-colourStr='#{$start-colour}', end-colourStr='#{$end-colour}');
}
.hidden-table {
    position: relative;
    @include res(tab) {
        &::before {
            display: none;
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            width: 50px;
            height: 100%;
            background: linear-gradient(to left, rgba(255, 255, 255, 0) 0, rgba(245, 245, 245, 1) 100%);
            content: '';
            z-index: 1;
        }
        &::after {
            position: absolute;
            pointer-events: none;
            top: 0;
            right: 0;
            width: 50px;
            height: 100%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(245, 245, 245, 1) 100%);
            content: '';
            z-index: 1;
        }
        &_middle {
            &::before {
                display: block;
            }
        }
        &_end {
            &::after {
                display: none;
            }
        }
    }
}

// CUSTOM DATATABLES PAGINATION:
.dataTables_wrapper .dataTables_paginate {
    padding-top: 20px;
    float: none!important;
    text-align: center!important;
    display: flex;
    justify-content: center;
    span {
        display: flex;
    }
    .paginate_button {
        color: $dark!important;
        font-family: $Roboto-BoldCondensed;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
        border: 1px dashed $middle-gray!important;
        border-right: none!important;
        border-radius: 0!important;
        background: none!important;
        min-width: 0!important;
        padding: 0!important;
        margin: 0!important;
        width: 36px;
        height: 36px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none!important;
        &.current {
            color: $yellow!important;
        }
        &.previous, &.next, &.disabled {
            color: transparent!important;
            position: relative;
        }
        &.next {
            border-right: 1px dashed $middle-gray!important;
        }
        &.next::before, &.previous::before {
            position: absolute;
            top: 50%;
            left: calc(50% + 2px);
            opacity: 1;
            border-color: $yellow;
            border-style: solid;
            border-width: 2px 2px 0 0;
            content: '';
            display: inline-block;
            height: 8px;
            width: 8px;
            vertical-align: middle;
            transition: all .5s ease;
            transform: translate(-50%, -50%) rotate(223deg);
        }
        &.next::before {
            left: calc(50% - 2px);
            transform: translate(-50%, -50%) rotate(43deg);
        }
        &.previous.disabled::before, &.next.disabled::before {
            opacity: .3;
            border-color: $gray;
        }
    }
}
