.accordion {
    &__arrow {
        position: relative;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        transition: transform .4s ease-out;
        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 32px;
            height: 32px;
            fill: #fcaf17;
        }
        .shown & {
            transform: rotate(-180deg);
            transition: transform .4s ease-out;
        }
        .accordion__table_img & {
            margin-left: 126px;
        }

    }
    &__content {
        display: none;
        background-color: #fff;
        table {
            border-spacing: 1px;
            border-collapse: separate;
        }
        .col__status {
            width: 145px;
        }
        .has-link {
            background-color: $white!important;
        }
        tbody tr {
            width: 100%;
            td {
                border-top: none!important;
                border-bottom: 1px dashed $middle-gray!important;
                &:last-child {
                    padding-right: 5px !important;
                }
                a {
                    padding: 0!important;
                }
            }
            &:last-child td {
                border-bottom: none!important;
            }
        }
        tbody tr:not(.even):not(.odd) {
            & > td {
                .accordion__table & {
                    padding: 0 5px!important;
                }
                &:last-child {
                    padding-right: 0 !important;
                }
            }
        }
        thead th {
            .dataTable & {
                border-bottom: 1px dashed $gray!important;
                &.style_center-text {
                    padding-left: 0!important;
                    padding-right: 0;
                }
            }
        }
    }
    &__slide {
        display: none;
    }
    &__img-wrapper {
        padding: 0!important;
        display: flex!important;
        background-color: $light-gray;
        position: relative;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            z-index: 1;
            svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 16px;
                height: 16px;
            }
            &.has-problem {
                background-color: $red;
            }
            &.no-problem {
                background-color: $green;
            }
        }
        img {
            transition: transform .5s ease;
        }
        &_large {
            width: 220px;
            height: 135px;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
                backface-visibility: hidden;
                transform: translateZ(0) scale(1.0, 1.0);
                -ms-transform: scale(1.0, 1.0);
            }
        }
        &_small {
            width: 145px;
            height: 95px;
            background-color: $light-gray;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
            svg {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 50px;
                height: 54px;
                fill: #999999;
            }
        }
        &:not(.accordion__img-wrapper-hover) {
            img {
                max-width: 58%;
                max-height: 58%;
                opacity: .4;
            }
        }
    }
    &__table {
        thead th {
            border-bottom: none!important;
            &.style_center-text.col__w200 {
                padding: 0 5px!important;
                &:last-child {
                    padding: 0 0 0 5px!important;
                }
            }
            &.style_center-text.col__w80 {
                &:last-child {
                    padding: 0 0 0 5px!important;
                }
            }
            &.style_center-text.col__w120 {
                padding: 0 5px!important;
            }
            &.style_center-text.col__w150 {
                padding: 0 5px!important;
            }
            &.col__w150 {
                padding: 0 5px!important;
            }
        }
        tbody tr {
            &:not(.even):not(.odd) {
                border-top: none!important;
                border-bottom: none!important;
                & > td {
                    padding: 0 !important;
                }
            }
            td .style_center-text {
                text-align: center;
            }
        }
        &_img {
            margin-top: 20px!important;
            & > thead {
                display: none;
            }
            tbody tr {
                &:first-child {
                    td {
                        border-top: none !important;
                    }
                }
                &:not(.even):not(.odd) {
                    & > td {
                        border-top: none!important;
                    }
                }
            }
            tr {
                &.even, &.odd {
                    height: 235px!important;
                    position: relative;
                }
                td {
                    border-top: 1px dashed $yellow!important;
                    border-bottom: none!important;
                    &:nth-child(1) {
                        min-width: 220px;
                    }
                    &:nth-child(2) {
                        width: 100%;
                    }
                }
            }
            .accordion__content {
                width: calc(100% - 145px);
                overflow: hidden;
                margin: 0 0 -52px 0;
                transform: translateY(-51px);
                tbody tr {
                    height: 135px;
                }
            }
        }
        &_text {
            & > tbody > tr {
                td {
                    border-top: 1px dashed $yellow!important;
                    border-bottom: none!important;
                    &:last-child {
                        padding-right: 0!important;
                    }
                }
                &:last-child > td {
                    border-bottom: 1px dashed $yellow!important;
                }
                &:not(.even):not(.odd) > td {
                    border-top: none!important;
                }
            }
            th {
                &.sorting, &.sorting_asc, &.sorting_desc {
                    background-image: none;
                    position: relative;
                    &.sorting-active {
                        cursor: pointer;
                        &::after {
                            content: "";
                            position: absolute;
                            background-image: url("../img/content/arrow_period_up_icon.svg");
                            background-repeat: no-repeat;
                            width: 6px;
                            height: 14px;
                            z-index: 1;
                            margin: -2px 0 0 2px;
                            transition: transform .5s ease;
                        }
                    }
                }
                &.sorting_asc.sorting-active {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
                &:first-child {
                    width: 60px;
                    max-width: 60px;
                    @include res(tab) {
                        width: 40px;
                        max-width: 40px;
                    }
                }
            }
            .accordion__arrow::after {
                right: 6px;
            }
            .accordion__content {
                padding-left: 83px;
                @include res(tab) {
                    padding-left: 63px;
                }
                thead {
                    display: none;
                }
                tbody tr {
                    height: 59px;
                    td {
                        border-top: 1px dashed $middle-gray!important;
                    }
                }
            }
            @include res(tab) {
                margin-top: -20px;
                .col__w200 {
                    width: 120px;
                }
                .col__w150 {
                    width: 100px;
                }
                .col__w120 {
                    width: 100px;
                }
            }
        }
        @include res(tab) {
            &.dataTable {
                width: 100%!important;
            }
            &.dataTable.accordion__table_large {
                width: 900px!important;
                .col__w200 {
                    width: 160px;
                }
            }
        }
    }
}

table th {
    outline-color: transparent;
}
