.popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
    z-index: 1050;
    overflow: auto;
    outline: 0;
    text-align: center;
    font-size: 0;
    transform: translate3d(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity .3s ease, transform .5s ease;
    will-change: opacity, transform;
    &::after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 100%;
    }
    form.no-visible {
        display: none;
    }
    &.opened {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        visibility: visible;
        .popup__container {
            transform: translate3d(0, 0, 0);
        }
    }
    &__close {
        position: absolute;
        top: 73px;
        right: 84px;
        padding-right: 30px;
        letter-spacing: -.2px;
        color: $yellow;
        font-family: $Roboto-Regular;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 18px;
        cursor: pointer;
        z-index: 2;
        transition: color .5s ease;
        &::before, &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 77px;
            transform: translate(-50%, -0.5px) rotate(45deg);
            background-color: $yellow;
            height: 1px;
            width: 15px;
            transition: background-color .4s ease;
        }
        &::after {
            transform: translate(-50%, -1.5px) rotate(-45deg);
        }
    }
    &__container {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        text-align: left;
        font-size: 12px;
        background-color: white;
        box-shadow: 0 3px 24px rgba(0, 0, 0, 0.12);
        margin: 30px auto;
        transition: opacity .2s ease, transform .3s ease, visibility 0s .1s;
        transform: translate3d(0, -50px, 0);
        will-change: transform, opacity;
    }
    .form-group:nth-child(2) {
        margin-bottom: 43px;
    }
    .download {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 28px;
        a {
            width: 115px;
            height: 115px;
            margin: 0 15px 30px;
            background-color: $yellow;
            position: relative;
            padding-top: 28px;
            &:first-child {
                margin-left: 0;
            }
            &:nth-child(3) {
                margin-right: 0;
            }
            svg {
                fill: $white;
                height: 50px;
                position: absolute;
                top: calc(50% - 3px);
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &.disabled {
                background-color: $brown;
                cursor: default;
            }
        }
    }
    &_download {
        .form__wrapper {
            min-height: inherit;
            padding-bottom: 40px;
        }
        .popup__close::before {
            transform: translate(-50%, -0.5px) rotate(45deg);
        }
    }
    @media (max-width: 767px) {
        padding: 20px 0;
        &__container {
            width: 80%;
            margin: 10px auto;
            padding: 20px 18px;
        }
        &__close {
            top: 10px;
            right: 10px;
        }
    }
}


