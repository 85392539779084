.submenu {
    &__list {
        display: flex;
        justify-content:space-between;
        flex-wrap: nowrap;
        @include res(tab){
            flex-wrap: nowrap;
        }
        &-item{
            font-family: $Roboto-BoldCondensed;
            line-height: 18px;
            text-transform: uppercase;
            background-color: $dark;
            text-align: center;
            flex-grow: 1;
            transition: .5s;
            box-sizing: border-box;
            height: 45px;
            a {
                color: $white;
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: center;
                letter-spacing: 1px;
                @include res(tab){
                    padding: 0 17px;
                }
            }
            img {
                display: none;
            }
            span {
                color: $dark;
                display: inline-block;
                margin-left: 7px;
                padding-left: 6px;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 4px;
                    width: 1px;
                    height: 9px;
                    background-color: $dark;
                }
            }
            &.active{
                background-color: $white;
                a {
                    color: $yellow;
                }
            }
            &.disabled {
                pointer-events: none;
                a {
                    opacity: .3;
                }
            }
            &:hover {
                .no-touch & {
                    background-color: $yellow;
                    transition: background-color .4s ease;
                    a {
                        color: $white;
                        transition: color .5s ease;
                    }
                }
            }
            @include res(tab) {
                height: 63px;
                &__icon {
                    display: flex;
                    i {
                        display: block;
                        img {
                            display: block;
                            height: 21px;
                            width: 15px;
                        }
                    }
                }
                &:last-of-type {
                    img {
                        height: 20px;
                        width: 20px;
                    }
                }
                &:nth-last-of-type(1) a:first-child {
                    display: none;
                }
                &:nth-last-of-type(2) a:first-child {
                    display: none;
                }
            }
        }
    }
    .page__wrapper {
        @include res(tab){
            width: 100%;
            padding: 0;
        }
    }
}
.show-tab{
    display: none;
    @include res(tab){
        display: block;
    }
}
.hide-tab{
    display: block;
    @include res(tab){
        display: none;
    }
}
