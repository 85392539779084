.dropdown {
    width: 200px;
    height: 40px;
    position: relative;
    border: 1px solid $yellow;
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        pointer-events: none;
        &::before {
            position: absolute;
            top: 14px;
            left: 7.5px;
            border-color: $dark;
            border-style: solid;
            border-width: 1px 1px 0 0;
            content: '';
            display: inline-block;
            height: 7px;
            width: 7px;
            vertical-align: middle;
            transform: rotate(133deg);
            transition: all .5s ease;
        }
    }
    &__title {
        width: 100%;
        padding: 0 35px 0 20px;
        line-height: 40px;
        letter-spacing: .4px;
        color: $dark;
    }
    &__content {
        padding: 18px 21px;
        visibility: hidden;
        position: absolute;
        bottom: 1px;
        left: -1px;
        height: auto;
        width: calc(100% + 2px);
        transform: translateY(100%);
        background-color: $white;
        border: 1px solid $yellow;
        z-index: 1;
        .form-group {
            margin-bottom: 6px;
            &:last-of-type {
                margin-bottom: 18px;
            }
        }
        &.is-visible {
            visibility: visible;
        }
        label {
            color: $dark;
            display: block;
            padding: 0 0 0 34px;
            letter-spacing: .3px;
            position: relative;
            cursor: pointer;
            user-select: none;
            input {
                width: 0;
                height: 0;
                visibility: hidden;
                & + div {
                    width: 20px;
                    height: 20px;
                    background-color: $white;
                    border: 1px solid $yellow;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    &::before {
                        content: '';
                        width: 14px;
                        height: 14px;
                        background-image: url("../img/content/checkmark_icon.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: none;
                    }
                }
                &:checked + div {
                    background-color: $yellow;
                    &::before {
                        display: block;
                    }
                }
            }
        }
        .btn-transparent {
            padding: 3.5px 39px;
        }
    }
    &.active {
        .dropdown__arrow::before {
            transform: rotate(-47deg);
            border-color: $yellow;
        }
    }
    @include res(tab) {
        width: calc(50% - 20px);
        margin: 0 0 20px 40px;
    }
}
