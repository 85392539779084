// Mixins.

@mixin nl {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style: none;
    list-style-position: outside;
}

@mixin cf {
    &:before,
    &:after {
        display: table;
        content: "";
    }

    &:after {
        clear: both;
    }

    .lt-ie8 & {
        zoom: 1;
    }
}

@mixin blocks-justify {
    text-align: justify;
    text-justify: newspaper;
}

$mat:'mat';
$mob:'mob';
$mob-p:'mob-p';
$mob-l:'mob-l';
$tad:'tad';
$tab:'tab';
$tab-p:'tab-p';
$tab-l:'tab-l';
$desk:'desk';
$desk-mid:'desk-mid';
$desk-hd:'desk-hd';
$mobile-max: 768px;
$tablet-max: 1200px;
$desktop-mid: 1600px;
$desktop-max: 1920px;

@mixin res ($media) {
    @if $media == $mob {
        @media only screen and (max-width: $mobile-max - 1) {
            @content;
        }
    } @else if $media == $tab {
        @media only screen and ( min-width: $mobile-max) and (max-width: $tablet-max - 1) {
            @content;
        }
    } @else if $media == $mat {
        @media only screen and ( max-width: $tablet-max - 1) {
            @content;
        }
    } @else if $media == $tab-p {
        @media only screen and (min-width: $mobile-max) and (max-width: $tablet-max - 1)/* and (orientation: portrait) */ {
            @content;
        }
    }@else if $media == $tab-l {
        @media only screen and (min-width: $mobile-max + 100px) and (max-width: $tablet-max - 1)/* and (orientation: landscape) */ {
            @content;
        }
    } @else if $media == $tad {
        @media only screen and (min-width: $mobile-max)  {
            @content;
        }
    }@else if $media == $desk {
        @media only screen and (min-width: $tablet-max) {
            @content;
        }
    }@else if $media == $mob-p {
        @media only screen and (max-width: $mobile-max - 1) and (orientation: portrait)  {
            @content;
        }
    }@else if $media == $mob-l {
        @media only screen and (max-width: $mobile-max - 1) and (orientation: landscape) {
            @content;
        }
    } @else if $media == $desk-mid {
        @media only screen and (min-width: $desktop-mid) {
            @content;
        }
    } @else if $media == $desk-hd {
        @media only screen and (min-width: $desktop-max) {
            @content;
        }
    } @else if $media == desk-maxh-700 {
        @media only screen and (min-width: $tablet-max) and (max-height: 700px) {
            @content;
        }
    }
}

// You can add your own mixins here:
