// fonts
$Roboto-Regular: 'Roboto-Regular', sans-serif;
$Roboto-BoldCondensed: 'Roboto-BoldCondensed', sans-serif;
$GothamProBlack: 'GothamProBlack', sans-serif;

//colors
$white: #ffffff;
$light-gray: #f5f5f5;
$middle-gray: #eeedec;
$gray: #828c97;
$dark-gray: #646c74;
$green: #a0d45c;
$yellow: #fcaf17;
$red: #d33737;
$brown: #4d3d1e;
$dark: #0e1e31;




