.info-board {
    width: 100%;
    padding: 66px 40px 63px;
    margin-bottom: 30px;
    text-align: center;
    border: 1px dashed $yellow;
    background-color: $white;
    &__title {
        color: $gray;
        font-family: $Roboto-BoldCondensed;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
        padding-bottom: 22px;
        position: relative;
        letter-spacing: 1.1px;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 233px;
            height: 0;
            border-top: 1px dashed $middle-gray;
        }
    }
    &__descr {
        color: $yellow;
        font-family: $GothamProBlack;
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 1.65px;
        margin-top: 19px;
    }
    &__number {
        color: $dark;
        font-family: $Roboto-BoldCondensed;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
        position: relative;
        letter-spacing: 1.1px;
        padding-top: 16px;
    }
    &__wrap {
        min-width: 515px;
        margin-right: 68px;
        display: flex;
        flex-direction: column;
    }
    &_small {
        width: 340px;
        margin-top: 34px;
        margin-bottom: 13px;
        padding: 35px 20px 30px;
        .info-board {
            &__title {
                padding-bottom: 15px;
            }
            &__descr {
                margin-top: 0;
                font-size: 15px;
                font-family: $Roboto-BoldCondensed;
            }
        }
    }
    @include res(tab) {
        width: calc(50% - 15px);
        padding: 46px 40px 43px;
        margin-bottom: 74px;
        &__wrap {
            width: 100%;
            margin-right: 0;
            flex-direction: row;
            justify-content: space-between;
        }
        &_small {
            width: 540px;
            margin-top: 0;
            margin-bottom: 0;
            padding: 35px 40px 30px;
        }
    }
}
