.line-list {
    position: absolute;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 270px);
    &__item1 {
        left: 0;
    }
    &__item2 {
        left: 293px;
    }
    &__item3 {
        left: 584px;
    }
    &__item4 {
        left: 876px;
    }
    &__item5 {
        left: 1169px;
    }
    @include res(tab) {
        width: calc(100% - 25px);
        min-height: calc(100vh - 264px);
        &__item2 {
            left: 180px;
        }
        &__item3 {
            left: 360px;
        }
        &__item4 {
            left: 540px;
        }
        &__item5 {
            left: 719px;
        }
    }
    @include res(tab-l) {
        display: none;
    }
}
