.main-header{
    flex-shrink: 0;
    background-color: $dark;
    height: 80px;
    padding-top: 20px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    z-index: 3;
    &__logo {
        width: 290px;
        flex-shrink: 0;
        &-link{
            position: relative;
            left: -125px;
            img {
                @include res(tab){
                    transform: scale(0.8);
                }
            }
        }
        @include res(tab){
            width: 195px;
            margin-top: 4px;
        }
    }
    &__settings{
        margin-right: 68px;
        @include res(tab){
            margin-right: 25px;
            flex-shrink: 0;
        }
    }
    &_full{
        .main-header__logo-link{
            left: -153px;
        }
        .main-header__btn{
            width: 630px;
            position: relative;
            top: 4px;
            .btn-transparent {
                padding: 3.5px 19px;
                span {
                    width: 7px;
                    height: 7px;
                }
            }
            @include res(tab){
                width: 100%;
                .btn-transparent{
                    min-width: 150px;
                }
            }

        }
    }
    &__manager{
        width: 254px;
        position: relative;
        top: -4px;
        @include res(tab){
            margin-right: 16px;
            min-width: 160px;
            top: -2px;
        }
    }
    &__enter {
        @include res(tab) {
            position: relative;
            left: 4px;
            flex-shrink: 0;
        }
    }
}
.header-link{
    color: $white;
    font-family: $Roboto-BoldCondensed;
    text-transform: uppercase;
    line-height: 40px;
    letter-spacing: 1.33px;
}
