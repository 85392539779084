@font-face {
    font-family: 'Roboto-Regular';
    src: url("../fonts/Roboto-Regular.eot");
    src: url("../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Regular.woff") format("woff"), url("../fonts/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-BoldCondensed';
    src: url("../fonts/Roboto-BoldCondensed.eot");
    src: url("../fonts/Roboto-BoldCondensed.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-BoldCondensed.woff") format("woff"), url("../fonts/Roboto-BoldCondensed.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Bold';
    src: url("../fonts/Roboto-Bold.eot");
    src: url("../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Roboto-Bold.woff") format("woff"), url("../fonts/Roboto-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamProBlack';
    src: url("../fonts/GothamProBlack.eot");
    src: url("../fonts/GothamProBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamProBlack.woff") format("woff"), url("../fonts/GothamProBlack.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

