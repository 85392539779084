.pagination {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    position: relative;
    z-index: 1;
    &__wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    &__prev.disabled, &__next.disabled {
        position: relative;
        &::before {

        }
    }
    li {
        position: relative;
        a {
            color: $dark;
            font-family: $Roboto-BoldCondensed;
            text-transform: uppercase;
            border: 1px dashed $middle-gray;
            border-right: none;
            border-radius: 0;
            background: none;;
            padding: 0;
            margin: 0;
            width: 36px;
            height: 36px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
        }
        &.active {
            a {
                color: $yellow;
            }
        }
    }
    &__next {
        border-right: 1px dashed $middle-gray!important
    }
    &__next a::before, &__previous a::before {
        position: absolute;
        top: 50%;
        left: calc(50% + 2px);
        opacity: 1;
        border-color: $yellow;
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        vertical-align: middle;
        transition: all .5s ease;
        transform: translate(-50%, -50%) rotate(223deg);
    }
    &__next a::before {
        left: calc(50% - 2px);
        transform: translate(-50%, -50%) rotate(43deg);
    }
    &__previous.disabled a::before, &__next.disabled a::before {
        opacity: .3;
        border-color: $gray;
    }
}
