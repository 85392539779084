.no-touch {
    .accordion {
        &__arrow:hover {
            svg {
                fill: #0e1e31;
                transition: all .4s ease;
            }
        }
        &__info:hover {
            div {
                color: $yellow;
                transition: color .5s ease;
            }
        }
        &__img-wrapper-hover:hover {
            img {
                transform: scale(1.1);
                -ms-transform: scale(1.1);
                transition: transform .5s ease;
            }
        }
        &__table {
            tr.even:hover, tr.odd:hover {
                //background-color: $light-gray;
            }
        }
    }
    .back-btn:hover {
        color: $dark;
        transition: color .4s ease;
    }
    .btn-color_orange {
        &:hover {
            transition: background-color .5s ease;
            background-color: $dark;
            span{
                background: url("../img/content/corner-orange.png") no-repeat;
            }
        }
    }
    .collapsed-btn:hover {
        span {
            background-color: $dark;
            transition: background-color .5s ease;
            &::before, &::after {
                background-color: $dark;
                transition: background-color .5s ease;
            }
        }
    }
    .dashboard {
        &__company-all {
            &:hover {
                color: $yellow;
                transition: color .5s ease;
                svg {
                    fill: $yellow;
                    transition: fill .5s ease;
                }
            }
        }
        &__info-charts__item-block {
            &_gray {
                &:hover {
                    transition: background-color .5s ease;
                    background-color: $gray;
                    span, p {
                        color: $white;
                        transition: color .5s ease;
                    }

                }
            }
            &_green {
                &:hover {
                    transition: background-color .5s ease;
                    background-color: $green;
                    span, p {
                        color: $white;
                        transition: color .5s ease;
                    }

                }
            }
            &_orange {
                &:hover {
                    transition: background-color .5s ease;
                    background-color: $yellow;
                    span, p {
                        color: $white;
                        transition: color .5s ease;
                    }
                }
            }
            &_red {
                &:hover {
                    transition: background-color .5s ease;
                    background-color: $red;
                    span, p {
                        color: $white;
                        transition: color .5s ease;
                    }
                }
            }
            &_dark {
                &:hover {
                    transition: background-color .5s ease;
                    background-color: $dark;
                    span, p {
                        color: $white;
                        transition: color .5s ease;
                    }
                }
            }
        }
        &__table {
            &-reset__link:hover {
                color: $dark;
                transition: color .5s ease;
            }
        }
        &__telephone-number:hover {
            transition: color .5s ease;
            color: $yellow;
        }
        &__telephone-callback:hover {
            transition: color .5s ease;
            color: $dark;
        }
    }
    .dataTables_scrollHead {
        table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
            transition: background .4s ease, color .4s ease;
            &::after {
                transition: background .4s ease, transform .3s ease;
            }
        }
    }
    .sorting-active:hover {
        color: $yellow;
        transition: color .4s ease;
    }
    .download a:not(.disabled):hover {
        transition: background-color .5s ease;
        background-color: $dark;
    }
    .header-link:hover {
        color: $yellow;
        transition: color .5s ease;
    }
    .form__remove:hover {
        color: $dark;
        transition: color .5s ease;
    }
    .outline-inward {
        &:hover {
            &:before{
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 1;
            }
        }
    }
    .pagination li:not(.disabled) a:hover {
        border: 2px solid $yellow;
    }
    .dataTables_wrapper .paginate_button:hover {
        color: $dark!important;
        transition: color .5s ease;
        &:not(.disabled) {
            border: 2px solid $yellow!important;
        }
        &.current {
            color: $yellow!important;
        }
        &.previous, &.next, &.disabled {
            color: transparent!important;
        }
    }
    .popup__close:hover {
        transition: color .5s ease;
        color: $dark;
        &::before, &::after {
            background-color: $dark!important;
            transition: all .5s ease;
        }
    }
    .select2-results__option--highlighted {
        color: $yellow!important;
        transition: color .5s ease;
    }
    .table__svg.table__svg_single:hover {
        cursor: pointer;
        background-color: $dark!important;
        transition: background-color .5s ease;
    }
    .table__svg_link:hover {
        background-color: $yellow!important;
        transition: background-color .4s ease;
    }
    .table__icon_link:hover {
        div {
            color: $yellow!important;
            transition: color .4s ease;
        }
        svg {
            fill: $yellow!important;
            transition: fill .4s ease;
        }
    }
    .table__svg_background-link:hover {
        background-color: $dark!important;
        transition: background-color .4s ease;
    }
    .with-link:hover {
        background-color: $light-gray;
        transition: background-color .5s ease;
    }
    .with-link.color-hover:hover {
        background-color: $white;
        a {
            color: $yellow!important;
            transition: color .4s ease;
        }
    }
}
