// Common styles of the project
html, body {
    height: 100%;
}

.cell-hidden {
    visibility: hidden;
    position: absolute;
}

.cell-date {
    display: block;
    &:first-child {
        margin-top: 10px;
    }
    &:last-child {
        margin-bottom: 10px;
    }
    &_hide {
        display: none;
    }
}

body {
    color: $dark;
    font-family: $Roboto-Regular;
    font-size: 13px;
    line-height: 24px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-weight: 300;
    &.popup-opened {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        overflow: hidden;
    }
}

.tab-menu {
    background-color: $white;
}

.page__wrapper {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    @include res(tab) {
        max-width: 100%;
        padding: 0 24px;
    }
    &_small {
        max-width: 960px;
        margin: 0 auto;
        @include res(tab) {
            max-width: 100%;
            padding: 0 25px;
        }
    }
}

.show-row {
    width: 100%;
    padding: 15px 0 25px;
    text-align: left;
    color: $yellow;
    position: relative;
    &::after {
        content: '';
        display: inline-block;
        height: 7px;
        width: 7px;
        margin: 0 0 5px 8px;
        border-color: $dark;
        border-style: solid;
        border-width: 1px 1px 0 0;
        vertical-align: middle;
        transform: rotate(133deg);
        transition: all .5s ease;
    }
    &.active {
        &::after {
            margin: 0 0 0 8px;
            border-color: $yellow;
            transform: rotate(-47deg);
        }
    }
}

.main-content {
    position: relative;
    flex: 1 0 auto;
    background-color: $light-gray;
    transition: transform .8s;
    display: flex;
    flex-direction: column;
    &.moving {
        transition: transform .8s;
        transform: translateY(-60px);
        & + footer {
            margin-top: -60px;
        }
    }
}

.notification-number {
    margin-right: 5px;
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.phone-mask {
    -webkit-appearance: none;
    box-shadow: none !important;
}

.phone-mask::-ms-clear {
    display: none;
}

.client {
    width: 100%;
    text-align: right;
    margin-bottom: -13px;
    font-family: $Roboto-BoldCondensed;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    &__number {
        color: $dark;
        letter-spacing: 1px;
    }
    &__title {
        margin-top: 12px;
        color: $yellow;
        font-size: 15px;
        letter-spacing: 1.65px;
    }
    @include res(tab) {
        margin-bottom: 0;
    }
}

// Forms:
.form {
    &__container {
        padding: 100px 0;
        .form__block {
            margin: -40px 0 52px;
        }
        .form-group:nth-child(2) {
            margin-bottom: 43px;
        }
        @include res(tab) {
            padding-top: 60px;
        }
    }
    &__wrapper {
        background-color: $white;
        width: 585px;
        min-height: 517px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        padding: 62px 89.5px 90px 89.5px;
        box-sizing: border-box;
        &.auto-height {
            min-height: auto;
        }
        .dashboard__content & {
            padding: 0;
            .form__caption {
                letter-spacing: 0;
            }
            .form__title {
                margin-left: 38px;
            }
            .form-group {
                margin-bottom: 0;
            }
            .btn-color {
                margin-top: 25px;
            }
            @include res(tab) {
                width: 100%;
            }
        }
        &_settings {
            padding: 33px 40px 52px!important;
            .btn-color {
                margin-top: 52px!important;
            }
        }
    }
    &__thanks {
        display: none;
        font-family: $Roboto-BoldCondensed;
        color: $dark;
        font-size: 16px;
        letter-spacing: .2px;
    }
    &__text {
        color: $dark;
        font-family: $Roboto-BoldCondensed;
        font-size: 14px;
        letter-spacing: .8px;
        line-height: 18px;
        display: flex;
        align-items: center;
    }
    &__title {
        margin-bottom: 27px;
    }
    &__caption {
        color: $gray;
        margin-bottom: 5px;
        position: absolute;
        letter-spacing: .45px;
        top: 0;
        left: 0;
        &_fixed-width {
            width: 195px;
        }
    }
    &__block {
        height: 95px;
        position: relative;
        display: flex;
        border-bottom: 1px dashed $middle-gray;
        .form__caption {
            display: flex;
            align-items: center;
            margin-bottom: 0;
            position: static;
        }
        &_with-button {
            margin-top: 35px;
            input {
                width: calc(100% - 240px);
            }
            .btn-transparent {
                padding: 8px 34px;
                float: right;
            }
            & + .btn-color {
                margin-top: 40px!important;
            }
        }
        &__without-border {
            border-bottom: none;
        }
        ul {
            flex-grow: 1;
            padding-top: 38px;
            li {
                display: flex;
                justify-content: space-between;
                margin-bottom: 7px;
            }
        }
    }
    &__double-block {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed $middle-gray;
        .form-group {
            padding-top: 66px!important;
            width: calc(50% - 12.5px);
            border-bottom: none!important;
            .form__caption {
                top: 34px;
            }
        }
    }
    &__radio {
        .form-group {
            display: flex;
            align-items: center;
            position: relative;
            padding-top: 0!important;
            margin-bottom: 0!important;
            margin-right: 30px;
            label {
                color: $dark;
                font-family: $Roboto-BoldCondensed;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
                margin-left: 10px;
                order: 1;
            }
            input {
                opacity: 0;
                height: 100%;
                position: absolute;
                cursor: pointer;
                z-index: 1;
                &:checked + span {
                    &::before {
                        content:'';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 50%;
                        width: 8px;
                        height: 8px;
                        background-color: $yellow;
                    }
                }
            }
            span {
                position: relative;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 2px solid $yellow;
            }
        }
    }
    &__remove {
        color: $red;
        font-family: $Roboto-BoldCondensed;
        font-size: 11px;
        line-height: 18px;
        text-transform: uppercase;
    }
}

.pseudo-form-group {
    position: relative;
    padding: 32px 0 40px;
    border-bottom: 1px dashed #eeedec;
}
.pseudo-input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: 1px solid #fcaf17;
    padding: 0 20px;
    box-sizing: border-box;
    user-select: none;
}

.line {
    width: 1px;
    height: 5000px;
    background-color: $middle-gray;
    display: inline-block;
    position: absolute;
    top: 0;
    z-index: 0;
}

//titles
.info-title {
    color: $dark;
    font-family: $GothamProBlack;
    position: relative;
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: .06em;
    &:before {
        content: '';
        width: 40px;
        height: 4px;
        background-color: $dark;
        position: absolute;
        top: 12px;
        transform: translateY(-50%);
        left: -61px;
    }
    &_regular {
        left: -38px;
        &::before {
            display: none;
        }
    }
    @include res(tab) {
        &:before {
            top: 13px;
        }
        .dashboard__table-title & {
            &:before {
                left: -64px;
            }
        }
    }
}

// Info-block:
.info {
    &__wrap {
        display: flex;
        margin-left: -12px;
    }
    &__block {
        color: $dark;
        font-family: $Roboto-BoldCondensed;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin: 0 16px -4px 0;
        &:last-child {
            margin-right: 0;
        }
    }
}

// Cabinet header

.company-title {
    color: $yellow;
    font-family: $GothamProBlack;
    text-transform: uppercase;
    letter-spacing: 0.065em;
    font-size: 24px;
}

//your-manager
.your-manager {
    display: flex;
    justify-content: flex-start;
    @include res(tab) {
        width: 160px;
    }
    &__photo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-top: 3px;
        img {
            max-height: 100%;
            max-width: 100%;
            height: auto;
            width: auto;
        }
    }
    &__info {
        color: $white;
        margin-left: 14px;
        display: flex;
        flex-direction: column;
        p {
            letter-spacing: .55px;;
        }
        span {
            color: $gray;
            letter-spacing: .1px;
        }
    }
}

// Form
.form-group {
    .form__wrapper & {
        position: relative;
        padding-top: 32px;
        margin-bottom: 35px;
        &:first-of-type {
            border-bottom: 1px dashed $middle-gray;
            padding-bottom: 40px;
        }
    }
}

.form-control {
    width: 100%;
    height: 40px;
    border: 1px solid $yellow;
    padding: 0 20px;
    box-sizing: border-box;
    color: $yellow;
    text-shadow: 0 0 0 $dark;
    -webkit-text-fill-color: transparent;
    ::-webkit-input-placeholder {
        color: $dark;
    }
    ::-moz-placeholder {
        color: $dark;
    }
    :-ms-input-placeholder {
        color: $dark;
    }
    :-moz-placeholder {
        color: $dark;
    }
}

.no-result {
    &__title {
        color: $red;
        font-family: $GothamProBlack;
        font-size: 24px;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        padding: 41px 0 18px;
    }
    &__descr {
        width: 50%;
        color: $dark;
        font-size: 14px;
        letter-spacing: .2px;
        margin-bottom: 100px;
    }
    @include res(tab) {
        &__title {
            padding: 0 0 18px;
            margin-top: -4px;
        }
        &__descr {
            width: inherit;
        }
    }
}

td {
    .no-result {
        @include res(tab) {
            &__title {
                margin-top: 20px;
                max-width: 710px;
            }
            &__descr {
                max-width: 710px;
            }
        }
    }
}

textarea.form-control {
    word-wrap: break-word;
    min-height: 70px;
    resize: none;
    line-height: 22px;
    padding: 8px 19px;
    overflow-x: hidden;
}

.help-block {
    color: $red;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}

.has-error {
    .form__caption {
        display: none;
    }
    .help-block {
        display: block;
    }
    .form-control {
        border: 1px solid $red;
        color: $red;
        text-shadow: 0 0 0 $red;
    }
}

// manager
.manager {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 63px 0 50px;
    min-height: 537px;
    background-color: $white;
    &__contacts {
        text-align: center;
        .manager__contact-item {
            span:first-child {
                margin-right: 5px;
            }
        }
    }
    &__status {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 57px;
        @include res(tab) {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
        }
    }
    &__phone {
        display: flex;
        flex-direction: column;
        margin: 16px 0 22px 0;
        .dashboard__telephone-number {
            line-height: 22px;
            margin-bottom: 0;
        }
    }
    &__wrapper {
        flex-grow: 1;
        padding-left: 145px;
    }
    &_grey {
        padding: 50px 0;
        min-height: auto;
        background-color: $light-gray;
    }
    .your-manager {
        flex-direction: column;
        align-items: center;
        text-align: center;
        p {
            color: $dark;
            font-size: 14px;
            margin-bottom: -4px;
        }
        &__info {
            margin: 24px 0 6px 0;
        }
        &__photo {
            width: 120px;
            height: 120px;

        }
    }
    .btn-transparent {
        padding: 4px 30px;
    }
    @include res(tab) {
        margin-top: 50px;
        min-height: 440px;
        &__wrapper {
            padding-left: 0;
            margin-top: 20px;
        }
        .your-manager {
            width: 80%;
        }
        .manager__phone {
            flex-direction: row;
            justify-content: center;
        }
        .dashboard__telephone-number {
            margin-right: 25px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

// select2
.select2-dropdown {
    border-radius: 0 !important;
    border: 1px solid $yellow !important;
    transform: translateY(-1px);
    &.select2-dropdown--above {
        transform: translateY(2px);
    }
    .select2-results {
        max-height: 220px;
        .mCSB_inside > .mCSB_container {
            margin-right: 20px;
        }
        .mCSB_draggerContainer {
            height: 92%;
            top: 4%;
            .mCSB_dragger_bar {
                background-color: $yellow !important;
                width: 2px;
            }
            .mCSB_draggerRail {
                background-color: $light-gray;
            }
        }
    }
    .select2-results__options {
        padding: 10px 0;
    }
    .select2-results__option {
        color: $dark !important;
        background-color: $white !important;
        letter-spacing: .32px;
        padding: 3px 0 3px 20px !important;
        &[aria-selected=true] {
            color: $yellow !important;
        }
    }
}

//base-dashboard
.dashboard {
    background-color: $light-gray;
    position: relative;
    padding-bottom: 100px;
    &__btn-more {
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }
    &__back {
        color: $yellow;
        font-family: $Roboto-BoldCondensed;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: absolute;
        left: 0;
        top: -15px;
        & + .dashboard__info_grey-2 {
            margin-top: 0;
            .dashboard__info-title {
                padding-top: 18px;
            }
        }
        @include res(tab) {
            left: 24px;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
    &__info {
        position: relative;
        z-index: 1;
        &-title {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            padding: 54px 0 22px 38px;
            &_search {
                padding-top: 44px;
                @include res(tab) {
                    padding-top: 40px!important;
                    .info-title {
                        margin-bottom: -4px;
                    }
                    .dashboard__search {
                        margin-bottom: 0;
                    }
                    form {
                        align-self: flex-start;
                        margin-left: 10px;
                    }
                }
            }
            &_without-top {
                padding: 0 0 40px 38px;
                @include res(tab) {
                    padding-top: 0!important;
                }
            }
            .dashboard__search {
                top: -4px;
                width: 586px;
                position: relative;
            }
            &_with-btn {
                padding: 36px 0 41px 38px !important;
                .info-title {
                    margin-bottom: -6px;
                }
                & + .dashboard__table {
                    padding-top: 38px;
                    .dashboard__form {
                        margin-top: 0;
                    }
                }
                @include res(tab) {
                    padding: 54px 0 21px 38px!important;
                    & + .dashboard__table {
                        margin-top: 18px!important;
                        padding-top: 32px;
                        .dashboard__form {
                            margin-top: 0;
                        }
                    }
                    & + .dashboard__info-charts {
                        margin-top: 26px;
                        padding-bottom: 26px!important;
                    }
                }
            }
            &_with-two-btn {
                padding: 0 0 39px 38px !important;
                .info-title {
                    margin-bottom: -6px;
                }
                & + .dashboard__table {
                    padding-top: 38px;
                    .dashboard__form {
                        margin-top: 0;
                    }
                }
                @include res(tab) {
                    flex-wrap: wrap;
                    align-items: flex-start;
                    padding: 6px 0 0 38px!important;
                    .info-title {
                        top: 12px;
                    }
                    .info__wrap {
                        order: 2;
                        width: calc(100% - 240px);
                        position: absolute;
                        top: 60px;
                        left: 12px;
                    }
                    .dashboard__info-btn {
                        flex-direction: column;
                        flex-grow: 0;
                        top: -24px!important;
                        position: relative;
                        .btn-transparent {
                            margin-left: 0!important;
                            margin-bottom: 9px;
                        }
                    }
                    & + .dashboard__table {
                        margin-top: -3px!important;
                        padding-top: 32px;
                    }
                    & + .dashboard__info-charts {
                        margin-top: 26px;
                        padding-bottom: 26px!important;
                    }
                }
            }
            &_with-btn-back {
                padding-top: 18px!important;
                .info__wrap {
                    margin: 0 0 7px -8px;
                }
            }
            @include res(tab) {
                padding: 54px 0 21px 38px;
                &_main {
                    padding-top: 24px;
                }
                .dashboard__search {
                    top: 16px;
                    width: calc(50vw - 24px);
                    position: static;
                }
                & + .dashboard__table {
                    margin-top: 12px;
                }
            }
        }
        &-charts {
            display: flex;
            justify-content: space-around;
            padding-bottom: 70px;
            &__item {
                width: calc(100% / 4);
                text-align: center;
                position: relative;
                &-list {
                    @include res(tab) {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 15px;
                        flex-grow: 1;
                    }
                }
                &-block {
                    min-width: 78px;
                    height: 60px;
                    display: inline-block;
                    box-sizing: border-box;
                    margin: 0 4px 20px;
                    text-align: center;
                    padding-top: 7px;
                    transition: .5s;
                    @include res(tab) {
                        min-width: 140px;
                        height: 38px;
                        padding: 5px 0 0;
                        margin-bottom: 10px;
                    }
                    span {
                        font-family: $Roboto-BoldCondensed;
                        line-height: 18px;
                        text-transform: uppercase;
                    }
                    p {
                        color: $dark-gray;
                        font-family: $Roboto-BoldCondensed;
                        font-size: 10px;
                        font-weight: 700;
                        line-height: 18px;
                        text-transform: uppercase;
                        letter-spacing: .85px;
                        padding: 0 9px;
                        @include res(tab) {
                            display: inline-block;
                            padding: 0 0 0 9px;
                        }
                    }
                    &_green {
                        border: 1px solid $green;
                        span {
                            color: $green;
                        }
                        &.active {
                            background-color: $green;
                            span, p {
                                color: $white;
                            }

                        }
                    }
                    &_orange {
                        border: 1px solid $yellow;
                        span {
                            color: $yellow;
                        }
                        &.active {
                            background-color: $yellow;
                            span, p {
                                color: $white;
                            }
                        }
                    }
                    &_red {
                        border: 1px solid $red;
                        span {
                            color: $red;
                        }
                        &.active {
                            background-color: $red;
                            span, p {
                                color: $white;
                            }
                        }
                    }
                    &_gray {
                        border: 1px solid $gray;
                        span {
                            color: $gray;
                        }
                        &.active {
                            background-color: $gray;
                            span, p {
                                color: $white;
                            }
                        }
                    }
                    &_dark {
                        border: 1px solid $dark;
                        span {
                            color: $dark;
                        }
                        &.active {
                            background-color: $dark;
                            span, p {
                                color: $white;
                            }
                        }
                    }
                    &_checked {
                        border: transparent;
                    }
                }
                &-chart {
                    width: 180px;
                    height: 180px;
                    margin: 0 auto 35px auto;
                    position: relative;
                    @include res(tab) {
                        width: 120px;
                        height: 120px;
                        margin: 0 auto 37px auto;
                    }
                }
                &-btn {
                    .btn-transparent {
                        span {
                            width: 12px;
                            height: 12px;
                        }
                        @include res(tab) {
                            padding: 8.5px 14px;
                        }
                    }
                }
                @include res(tab) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
            &_compressed {
                margin: 0 147px;
                padding-bottom: 50px;
                @include res(tab) {
                    margin: 0;
                    padding-bottom: 45px;
                    justify-content: space-between
                }
            }
            &_without-bottom {
                padding-bottom: 0;
            }
            &_single {
                & + .dashboard__table {
                    padding-top: 30px;
                    margin-top: -20px;
                }
                @include res(tab) {
                    & + .dashboard__table {
                        margin-top: -50px;
                    }
                    .dashboard__info-charts__item {
                        margin-bottom: 8px;
                    }
                    .dashboard__info-charts__item-list {
                        flex-direction: row;
                        align-items: flex-start;
                        margin-bottom: 30px;
                    }
                    .dashboard__info-charts__item-block {
                        min-width: 120px;
                        &:last-child {
                            margin-bottom: 0!important;
                        }
                    }
                }
            }
        }
        &-btn {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            .btn-transparent {
                padding: 9px 40px;
                height: 42px;
                &:nth-child(2) {
                    margin-left: 20px;
                }
                span {
                    width: 12px;
                    height: 12px;
                }
            }
            @include res(tab) {
                align-self: flex-start;
                .btn-transparent {
                    top: 5px;
                    min-width: 220px;
                    padding: 9px 20px;
                }
            }
        }
        &_grey {
            padding-top: 43px;
            background-color: $light-gray;
            & + .dashboard__content {
                margin-top: 70px;
            }
            .dashboard__info-charts {
                padding-bottom: 50px;
                &__item-chart {
                    margin-bottom: 36px;
                }
                @include res(tab) {
                    padding-bottom: 32px;
                }
            }
        }
        &_grey-2 {
            .dashboard__info-title {
                padding-bottom: 35px!important;
            }
            .dashboard__info-charts {
                background-color: $white;
                flex: 1;
                padding-bottom: 0;
                &__item {
                    background-color: $light-gray;
                    flex-grow: 1;
                    margin: 0 10px;
                    @include res(tab) {
                        margin: 0 10px;
                        &-list {
                            min-height: 0;
                        }
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &-chart {
                        margin: 0 auto 8px auto;
                        @include res(tab) {
                            margin: 0 auto 65px auto;
                        }
                    }
                    &-block {
                        margin: 0 4px 40px;
                        @include res(tab) {
                            margin-bottom: 10px;
                            &:last-child {
                                margin-bottom: 40px;
                            }
                        }
                    }
                }
                @include res(tab) {
                    padding-bottom: 20px;
                }
            }
            & + .dashboard__info {
                margin-top: 70px!important;
            }
        }
        & + .dashboard__info {
            margin-top: 64px;
            .dashboard__info-title {
                padding-bottom: 34px!important;
                &_with-btn {
                    padding-bottom: 39px !important;
                    padding-top: 5px!important;
                }
            }
            .dashboard__form {
                margin-top: 0;
            }
            @include res(tab) {
                margin-top: 28px!important;
                .dashboard__info-title {
                    padding-bottom: 25px!important;
                    &_with-btn {
                        padding-bottom: 12px !important;
                        padding-top: 21px!important;
                    }
                }
            }
        }
    }
    &__form {
        color: $gray;
        position: relative;
        display: flex;
        align-items: flex-end;
        margin: -14px 0 20px;
        flex-wrap: nowrap;
        @include res(tab) {
            margin: -21px 0 0;
        }
    }
    &__calendar {
        display: inline-block;
        label {
            position: relative;
            margin-right: 15px;
            &:after {
                content: '';
                position: absolute;
                width: 16px;
                height: 15px;
                background: url("../img/content/calendar_after_icon.svg");
                top: 0;
                right: -53px;
            }
            &.active_yellow {
                &:after {
                    background: url("../img/content/calendar_after_icon_yellow.svg");
                }
            }
            @include res(tab) {
                display: block;
                &:after {
                    top: 35px;
                    right: 100%;
                    left: 15px;
                }
            }
        }
        &:nth-child(1) label {
            &:after {
                background: url("../img/content/calendar_before_icon.svg");
            }
            &.active_yellow:after {
                background: url("../img/content/calendar_before_icon_yellow.svg");
            }
        }
        input {
            width: 185px;
            height: 40px;
            padding-left: 45px;
            box-sizing: border-box;
            border: 1px solid $yellow;
            background-color: #ffffff;
            margin-right: 15px;
            cursor: pointer;
            @include res(tab) {
                width: 165px;
                margin-right: 0;
            }
        }
        @include res(tab) {
            &:last-child {
                margin-left: 10px;
            }
            &-wrap {
                display: flex;
                order: -1;
                width: calc(50% - 20px);
                margin: 0 40px 20px 0;
                & + select + .select2 {
                    margin-bottom: 20px;
                }
            }
            display: block;
        }
    }
    &__company {
        width: 100%;
        flex-grow: 1;
        padding: 58px 0 31px 0;
        position: relative;
        z-index: 1;
        &-all {
            margin-bottom: 32px;
            color: $dark;
            font-family: $Roboto-BoldCondensed;
            font-size: 13px;
            font-weight: 700;
            line-height: 18px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            letter-spacing: 1.1px;
            svg {
                width: 16px;
                height: 7.5px;
                fill: $dark;
            }
            span {
                margin-left: 20px;
            }
        }
        &-info {
            &__data {
                text-transform: uppercase;
                letter-spacing: 0.065em;
                font-family: $Roboto-BoldCondensed;
                span {
                    color: $dark;
                    font-family: $Roboto-BoldCondensed;
                    line-height: 18px;
                }
                p {
                    display: inline-block;
                    font-family: $Roboto-BoldCondensed;
                    color: $gray;
                    letter-spacing: 1px;
                    margin-left: 24px;
                    span {
                        margin-left: 4px;
                        letter-spacing: .7px;
                    }
                }
            }
            &__name {
                margin-bottom: 11px;
            }
        }
        @include res(tab) {
            padding: 56px 0 31px 0;
        }
    }
    &__contacts {
        min-width: 380px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 0 47px 0;
        @include res(tab) {
            padding: 0 0 31px 0;
            flex-direction: column;
        }
        .your-manager {
            &__photo {
                margin-top: 8px;
            }
            &__info {
                margin-left: 15px;
                position: relative;
                bottom: -2px;
                p {
                    color: $dark;
                    font-size: 14px;
                    letter-spacing: 0.3px;
                }
            }
            @include res(tab) {
                width: 100%;
                margin-bottom: 10px;
                justify-content: flex-end;
                &__photo {
                    order: 1;
                    margin-top: 4px;
                    margin-left: 15px;
                }
                &__info {
                    text-align: right;
                }
            }
        }
    }
    &__content {
        display: flex;
        position: relative;
        .dashboard__table-btn {
            padding-bottom: 65px;
            margin-top: 50px;
        }
        .dashboard__table-title {
            position: relative;
            top: -5px;
            margin-bottom: 11px;
            .info-title_regular {
                margin-left: 0;
            }
        }
        .info-title_regular {
            left: 0;
        }
        table {
            tbody tr td {
                &:first-child {
                    width: 96px;
                    padding-left: 5px;
                }
                padding: 16px 5px !important;
            }
        }
        .table__notification {
            tbody tr td {
                padding: 7px 5px!important;
                height: 40px;
            }
        }
        @include res(tab) {
            flex-direction: column;
            .dashboard__table-title {
                left: 4px;
            }
        }
    }
    &__search {
        height: 40px;
        flex-grow: 1;
        input {
            height: 100%;
            width: 100%;
            padding-left: 20px;
            border: 1px solid #fcaf17;
            background-color: #ffffff;
            color: #0e1e31;
            &::-webkit-input-placeholder {
                color: $dark;
            }
            &::-moz-placeholder {
                color: $dark;
            }
            &:-ms-input-placeholder {
                color: $dark;
            }
            &:-moz-placeholder {
                color: $dark;
            }
        }
        span {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                background: url("../img/content/001-magnifying-glass.svg") no-repeat;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                width: 18px;
                height: 18px;
            }
            &.active {
                cursor: pointer;
                &::after {
                    content: '';
                    position: absolute;
                    background: url("../img/content/cross_icon.svg") no-repeat;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 14px;
                    height: 14px;
                }
            }
        }
        &_large {
            position: absolute;
            top: 0;
            right: 0;
            input {
                width: 400px;
            }
            @include res(tab) {
                position: static;
                flex-grow: 1;
                margin-left: 40px;
                input {
                    width: 100%;
                }
            }
        }
        &_medium {
            position: absolute;
            top: 0;
            right: 0;
            input {
                width: 295px;
            }
            @include res(tab) {
                position: static;
                flex-grow: 1;
                input {
                    width: 100%;
                }
            }
        }
        @include res(tab) {
            flex-grow: 0;
            order: -1;
            width: calc(50% - 20px);
            margin-bottom: 20px;
        }
    }
    &__select {
        width: 200px;
        & + .select2 {
            margin: 0 20px 0 5px;
            .select2-selection {
                height: 40px;
                border-radius: 0;
                border: 1px solid $yellow;
                &__arrow b {
                    border-style: inherit !important;
                    left: 0 !important;
                    margin: 0 0 0 -3px !important;
                    top: 5px !important;
                    &::before {
                        border-color: $dark;
                        border-style: solid;
                        border-width: 1px 1px 0 0;
                        content: '';
                        display: inline-block;
                        height: 7px;
                        width: 7px;
                        vertical-align: middle;
                        transform: rotate(133deg);
                        transition: all .5s ease;
                    }
                }
                &__rendered {
                    height: 100%;
                    line-height: 40px !important;
                    padding-right: 30px !important;
                    margin-left: 12px;
                    letter-spacing: .45px;
                    color: $dark;
                }
            }
            &.select2-container--open {
                .select2-selection__arrow b {
                    &::before {
                        border-color: $yellow;
                        transform: rotate(-47deg);
                    }
                }
            }
            @include res(tab) {
                margin: 0;
                width: calc(50% - 20px)!important;
            }
        }
    }
    &__table {
        background-color: $white;
        padding: 53px 40px 70px 40px;
        position: relative;
        &-mask {
            position: relative;
            @include res(tab) {
                overflow-x: scroll;
                overflow-y: hidden;
            }
        }
        &-title {
            margin-bottom: 16px;
            .info-title_regular {
                margin-left: 38px;
                &:nth-child(2) {
                    color: $yellow;
                }
            }
        }
        &-reset {
            display: inline-block;
            margin-left: 28px;
            &__link {
                color: $red;
                font-family: $Roboto-BoldCondensed;
                line-height: 18px;
                text-transform: uppercase;
                transition: .5s;
                letter-spacing: 0.06em;
            }
        }
        &-btn {
            display: inline-block;
            min-width: 240px;
            margin-top: 50px;
            @include res(tab) {
                margin-top: 35px;
            }
        }
        &_pad-top-small {
            padding-top: 27px;
        }
        &_notification {
            margin-top: 11px;
            padding: 40px 40px 70px 40px;
            .dashboard__form {
                margin-top: 0;
                .select2 {
                    margin: 0;
                }
                @include res(tab) {
                    .select2 {
                        width: 200px!important;
                    }
                    margin-bottom: 22px;
                }
            }
        }
        & + .dashboard__table {
            margin-top: 45px;
        }
    }
    &__telephone {
        text-align: right;
        &-number {
            color: $dark;
            display: block;
            font-family: $Roboto-BoldCondensed;
            font-size: 15px;
            margin-bottom: 5px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
        }
        &-callback {
            color: $yellow;
            display: block;
            font-family: $Roboto-BoldCondensed;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        @include res(tab) {
            display: flex;
            align-items: center;
            &-number {
                margin-bottom: 0;
            }
            &-callback {
                order: 0;
                margin-left: 28px;
            }
        }
    }
    &_submenu {
        padding-bottom: 0;
        overflow: hidden;
        & + .dashboard {
            background-color: $white;
            padding-top: 50px;
            padding-bottom: 100px;
            flex-grow: 1;
            .dashboard {
                &__info-title {
                    padding-top: 0;
                    &_with-btn {
                        padding: 0 0 40px 38px!important;
                        @include res(tab) {
                            padding: 0 0 13px 38px!important;
                        }
                    }
                }
                &__form {
                    flex-wrap: wrap;
                }
                &__info-title {
                    padding-bottom: 47px;
                }
                &__info-btn {
                    top: 0;
                    .btn-transparent {
                        top: 0; // 4 page
                    }
                }
                &__table {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
}

//charts
.chart {
    width: 187px !important;
    height: 187px !important;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    left: 50%;
    &_medium {
        width: 165px !important;
        height: 165px !important;
        top: 11px;
    }
    &_small {
        width: 141px !important;
        height: 141px !important;
        top: 23px;
    }
    &_smaller {
        width: 128px !important;
        height: 128px !important;
        top: 30px;
        & + .chart-caption {
            top: calc(50% + 10px);
        }
    }
    &_smaller-1 {
        width: 113px !important;
        height: 113px !important;
        top: 38px;
    }
    &_smaller-2 {
        width: 98px !important;
        height: 98px !important;
        top: 46px;
    }
    &-caption {
        font-family: $GothamProBlack;
        position: absolute;
        font-size: 24px;
        top: calc(50% + 9px);
        left: 50%;
        transform: translate(-50%, -50%);
        svg {
            width: 27px;
        }
        @include res(tab) {
            top: calc(50% + 15px);
        }
    }
    @include res(tab) {
        width: 133px !important;
        height: 133px !important;
        &_medium {
            width: 115px !important;
            height: 115px !important;
            top: 9px;
        }
        &_small {
            width: 95px !important;
            height: 95px !important;
            top: 19px;
        }
        &-caption {
            svg {
                width: 30px;
            }
        }
        &_smaller {
            top: 28px;
            & + .chart-caption {
                top: calc(50% + 44px);
            }
        }
        &_smaller-1 {
            width: 118px !important;
            height: 118px !important;
            top: 36px;
        }
        &_smaller-2 {
            width: 104px !important;
            height: 104px !important;
            top: 44px;
            & + .chart-caption {
                top: calc(50% + 43px);
            }
        }
    }
}

//datepicker
.ui-widget.ui-widget-content {
    border: 1px solid $yellow !important;
    width: 185px !important;
    height: 215px !important;
    padding-top: 10px !important;
    box-sizing: border-box;
    border-top: none !important;
    @include res(tab) {
        width: 165px !important;
    }
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    width: 20px !important;
    height: 20px !important;
    border: 1px dashed $middle-gray !important;
    background: $white !important;
    color: $yellow !important;
    line-height: 14px !important;
    transition: .5s;
    text-align: center;
    &:hover {
        background: $yellow !important;
        color: $white !important;
    }
}

.ui-datepicker th {
    color: $dark-gray;
    font-family: $Roboto-BoldCondensed;
    font-size: 9px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.12em;
}

.ui-datepicker .ui-datepicker-header {
    background: $white !important;
    border: none !important;
    color: $dark;

    line-height: 18px;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    border-bottom-right-radius: 0 !important;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: 0 !important;
}

.ui-datepicker .ui-datepicker-next {
    span {
        background: url("../img/content/arrow_accordeon_down_icon.svg") !important;
        width: 15px !important;
        height: 15px !important;
        cursor: pointer;
        transform: rotate(270deg) translateZ(0);
    }
}

.ui-datepicker .ui-datepicker-prev {
    span {
        background: url("../img/content/arrow_accordeon_down_icon.svg") !important;
        width: 15px !important;
        height: 15px !important;
        cursor: pointer;
        transform: rotate(90deg) translateZ(0);
    }
}

.ui-datepicker .ui-datepicker-next-hover, .ui-datepicker .ui-datepicker-prev-hover {
    border: none !important;
    background: none !important;

}

.ui-datepicker .ui-datepicker-next-hover {
    right: 2px !important;
    top: 2px !important;
}

.ui-datepicker .ui-datepicker-prev-hover {
    left: 2px !important;
    top: 2px !important;
}

.ui-datepicker-today .ui-state-default {
    background: $yellow !important;
    color: $white !important;
    border: none !important;
    text-align: center;
    line-height: 15px !important;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-top-right-radius: 0 !important;
}

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    border-top-left-radius: 0 !important;
}

.ui-datepicker th {
    padding: .3em .3em;
}
