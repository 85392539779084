//btn with corner
button{
    border: none;
    background-color: transparent;
}

input {
    border-radius: 0;
}

.btn-transparent {
    text-align: center;
    color: $white;
    font-family:$Roboto-BoldCondensed;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 23px;
    letter-spacing: 1px;
    span {
        background: url(../img/content/corner-white.png) no-repeat;
        position: absolute;
        opacity: 0.6;
        width: 6px;
        height: 6px;
    }
    &_big{
        padding: 18px 54px;
        min-width: 208px;
        @include res(tab){
            padding: 15px 30px;
            min-width: 180px;
        }
    }
    &_dark {
        color: $dark;
        padding: 9px 39px;
        span{
            background: url(../img/content/corner-blue.png) no-repeat;
        }
        &.outline-inward_menu{
            &:before{
                border: $dark solid 1px;
            }
        }
        @include res(tab){
            padding: 5px 10px;
        }
    }
    &.disabled {
        opacity: .4;
        cursor: default;
    }
}
.btn-color {
    text-transform: uppercase;
    font-family: $Roboto-BoldCondensed;
    font-size: 13px;
    line-height: 15px;
    padding: 15px 0;
    position: relative;
    letter-spacing: 2px;
    width: 100%;
    text-align: center;
    max-width: 240px;
    span {
        position: absolute;
        width: 12px;
        height: 12px;
    }
    &_orange {
        background-color: $yellow;
        color: $white;
        transition: .5s;
        span {
            background: url("../img/content/corner-blue.png") no-repeat;
        }
        &.outline-inward {
            &:before{
                border: $dark solid 1px;
            }
        }
        &.disabled {
            background-color: $brown;
            &::before {
                display: none;
            }
            span {
                display: none;
            }
        }
    }
}
.top-left {
    left: 0;
    top: 0;
}

.top-right {
    right: 0;
    top: 0;
    transform: rotate(90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
}

.bottom-left {
    bottom: 0;
    left: 0;
    transform: rotate(-90deg);
}

.bottom-right {
    bottom: 0;
    right: 0;
    transform: rotate(-180deg);
}

//animation btn

.outline-inward {
    display: inline-block;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px transparent;
    &:before{
        content: '';
        position: absolute;
        border: #f8b344 solid 2px;
        top: -16px;
        right: -16px;
        bottom: -16px;
        left: -16px;
        opacity: 0;
        transition-duration: .3s;
        -webkit-transition-property: top right bottom left;
        -moz-transition-property: top right bottom left;
        -ms-transition-property: top right bottom left;
        -o-transition-property: top right bottom left;
        transition-property: top right bottom left;
    }
    &:hover {
        .no-touch & {
            &:before{
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                opacity: 1;
            }
        }
    }
}
.outline-inward_menu{
    &:before{
        border: #fff solid 2px;
        top: -9px;
        right: -9px;
        bottom: -9px;
        left: -9px;
    }
}
