.main-footer {
    min-height: 170px;
    background-color: $dark;
    padding: 38px 0 38px 0;
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    &__item {
        width: 25%;
        color: $white;
        line-height: 18px;
        &:first-child {
            width: calc(25% - 5px);
        }
        @include res(tab) {
            width: 33%;
            &:nth-child(3) {
                position: absolute;
                right: 10px;
                padding-left: 55px;
            }
            &:last-child {
                margin-top: 40px;
                padding-left: 55px;
            }
        }
        a {
            color: $white;
        }
        &-tel, &-mail {
            display: block;
            &:hover {
                .no-touch & {
                    color: $yellow;
                    transition: color .5s ease;
                }
            }
        }
        &-btn {
            float: right;
            position: relative;
            top: -9px;
            min-width: 220px;
            .btn-transparent {
                padding: 16px 49px;
                span {
                    width: 8px;
                    height: 8px;
                }
            }
        }
        &-vintage {
            padding-top: 50px;
            svg {
                fill: $white;
                &:hover {
                    .no-touch & {
                        fill: $yellow;
                        transition: all .5s ease;
                    }
                }
            }
        }
        &-address {
            &:first-of-type {
                padding-top: 18px;
            }
        }
        &-social {
            font-style: normal;
            overflow: hidden;
            margin-left: 13px;
            &__icon {
                margin-right: 20px;
                svg {
                    fill: $white;
                    transition: .5s;
                }
            }
            &:focus .social-anim {
                transform: translateY(-100%);
            }
            &:hover .social-anim {
                .no-touch & {
                    transform: translateY(-100%);
                }
            }
            .social-anim {
                transition: transform 0.5s ease;
                -webkit-transition: transform 0.5s ease;
                position: relative;
                display: inline-block;
                width: 120px;
                &:before {
                    position: absolute;
                    top: 100%;
                    content: attr(data-hover);
                    font-family: Roboto-Bold, sans-serif;
                    -webkit-transform: translate3d(0, 0, 0);
                    -moz-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }
                &:link, &:visited, &:active {
                    text-decoration: none;
                }
            }
        }

    }
}



