.tab-menu {
    &__list{
        display: flex;
        min-height: 60px;
        justify-content:space-between;
        flex-wrap: nowrap;
        padding: 0 4px;
        background-color: $light-gray;;
        @include res(tab){
            flex-wrap: nowrap;
        }
        &-item{
            font-family: $Roboto-BoldCondensed;
            line-height: 18px;
            text-transform: uppercase;
            background-color: $white;
            text-align: center;
            flex-grow: 1;
            margin: 0 2px;
            transition: .5s;
            height: 60px;
            box-sizing: border-box;
            border: 2px solid transparent;
            a {
                color: $yellow;
                padding: 0 20px;
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: center;
                letter-spacing: 1.15px;
                @include res(tab){
                   padding: 0 15px;
                    letter-spacing: 1px;
                }
            }
            i {
                display: block;
                img {
                    height: 21px;
                    width: 15px;
                }
            }
            span {
                color: $dark;
                display: inline-block;
                margin-left: 7px;
                padding-left: 6px;
                position: relative;
                &:after{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 1px;
                    height: 9px;
                    background-color: $dark;
                    transform: translateY(-50%);
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
                a {
                    &:first-child{
                        @include res(tab){
                            display: none;
                        }
                    }
                }
            }
            &.active{
                background-color:$yellow;
                a {
                    color:$white;
                }
            }
            &.disabled {
                pointer-events: none;
                a {
                    opacity: .3;
                }
            }
            &:not(.disabled):hover {
                .no-touch & {
                    transition: all.5s ease;
                    border: 2px solid $yellow;
                    a {
                        transition: color .5s ease;
                    }
                }
            }
            &__icon {
                @include res(tab){
                    display: flex;
                }
            }
        }
    }
    &_collapsed .collapsed-btn {
        display: block!important;
    }
    .collapsed-btn {
        display: none;
        position: absolute;
        left: 50%;
        bottom: -3px;
        transform: translate(-50%, 50%);
        background-color: $white;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        cursor: pointer;
        z-index: 2;
        span {
            position: absolute;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            &:before, &:after {
                position: absolute;
                content: '';
            }
            &:before {
                margin-top: -6px;
            }
            &:after {
                margin-top: 6px;
            }
        }
        span, span:before, span:after {
            display: block;
            width: 20px;
            height: 2px;
            background: $yellow;
            transition: all 200ms ease;
        }
    }
    .page__wrapper {
        width: 1177px;
        max-width: 1177px;
        @include res(tab){
            width: 100%;
            padding: 0 20px;
        }
    }
    .tab-menu__list-item {
        position: relative;
        z-index: 3;
        @include res(tab){
            a {
                max-width: 120px;
                margin: 0 auto;
            }
        }
        &__icon {
            display: none;
            @include res(tab){
                display: flex;
            }
        }
    }
}
.show-tab{
    display: none;
    @include res(tab){
        display: block;
    }
}
.hide-tab{
    display: block;
    @include res(tab){
        display: none;
    }
}
